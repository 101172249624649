import React from "react";
import classNames from "classnames";
import { SectionTilesProps } from "../../utils/SectionProps";
import SectionHeader from "./partials/SectionHeader";

const propTypes = {
  ...SectionTilesProps.types,
};

const defaultProps = {
  ...SectionTilesProps.defaults,
};

const Testimonial = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {
  const outerClasses = classNames(
    "testimonial section",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "testimonial-inner section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  const tilesClasses = classNames("tiles-wrap", pushLeft && "push-left");

  const sectionHeader = {
    title: "Show Room",
    paragraph: "",
  };

  const showroom = [
    {
      appName: "Procruit-C",
      type: "WEBSITE",
      content:
        "Procruit-C is a one-year professional traineeship programme created by The Hong Kong Jockey Club Charities Trust. Cooperate with some Industry Convenors and Education and Tradining partners. It offers first-time jobseekers an opportunity to innovate and create social good at workplace and contribute to the community’s recovery and resilience.",
      link: "https://procruitc.hk",
      images: [{}],
    },
    {
      appName: "Step First Broker System",
      type: "APP",
      content:
        "Step First broker system is a tailor made system for our client’s business, it includes a website dashboard and mobile application.",
      link: "",
      images: [{}],
    },
    {
      appName: "OnMyGrad",
      type: "APP",
      content:
        "OnMyGrad is a recruitment APP, and it has three main features which help users to be one step ahead of other undergraduate students.",
      link: "",
      images: [{}],
    },
    {
      appName: "MINEX.HK",
      type: "WEBSITE",
      content:
        "This is an animated web-app, providing our client information of the products they sell, a snapshot of the current bid/ask price. It also has a simple membership system for those who want to receive more discounts on every online purchase.",
      link: "http://minex.hk/hk",
      images: [{}],
    },
    {
      appName: "12 GYM",
      type: "APP",
      content:
        "12 GYM is a tailor made booking system for customers to reserve their gym facilities. This system separated into three sections, which are booking, attendance and Customer Related Management (CRM)",
      link: "",
      images: [{}],
    },
  ];
  return (
    <section {...props} className={outerClasses}>
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className={tilesClasses}>
            {showroom.map((v, i) => {
              const revealForm =
                i % 2 === 0
                  ? "reveal-from-bottom"
                  : i % 3 === 0
                  ? "reveal-from-left"
                  : "reveal-from-right";
              return (
                <div
                  className={classNames("tiles-item", revealForm)}
                  // className="tiles-item reveal-from-right"
                  data-reveal-delay="200"
                >
                  <div className="tiles-item-inner">
                    <div className="testimonial-item-content">
                      <p className="text-sm mb-0">{v.content}</p>
                    </div>
                    <div className="testimonial-item-footer text-xs mt-32 mb-0 has-top-divider">
                      <span className="testimonial-item-name text-color-high">
                        {v.type}
                      </span>
                      <span className="text-color-low"> / </span>
                      <span className="testimonial-item-link">
                        <a href={v.link} target="_blank">
                          {v.appName}
                        </a>
                      </span>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </section>
  );
};

Testimonial.propTypes = propTypes;
Testimonial.defaultProps = defaultProps;

export default Testimonial;
