import React from "react";
import classNames from "classnames";
import { SectionSplitProps } from "../../utils/SectionProps";
import SectionHeader from "./partials/SectionHeader";
import Image from "../elements/Image";
import _ from "lodash";

const propTypes = {
  ...SectionSplitProps.types,
};

const defaultProps = {
  ...SectionSplitProps.defaults,
};

const FeaturesSplit = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  invertMobile,
  invertDesktop,
  alignTop,
  imageFill,
  ...props
}) => {
  const outerClasses = classNames(
    "features-split section",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "features-split-inner section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  const splitClasses = classNames(
    "split-wrap",
    invertMobile && "invert-mobile",
    invertDesktop && "invert-desktop",
    alignTop && "align-top"
  );

  const sectionHeader = {
    title: "We work like this",
    paragraph: "",
  };

  const steps = [
    {
      step: "Step 1",
      title: "Kick-Start and Consultation",
      content: "We provide full range of technical support",
      image: require("./../../assets/images/borderless/steps/step-1.jpg"),
    },
    {
      step: "Step 2",
      title: "UI/UX Design",
      content:
        "We consider about the UI/UX, we will tailor made for each client",
      image: require("./../../assets/images/borderless/steps/step-2.jpg"),
    },
    {
      step: "Step 3",
      title: "Development",
      content:
        "Under the development, we will always update the progress to you",
      image: require("./../../assets/images/borderless/steps/step-3.jpg"),
    },

    {
      step: "Step 4",
      title: "Deployment & Launch",
      content:
        "Congrations, Your project launched. We will handle all of the technical issue",
      image: require("./../../assets/images/borderless/steps/step-4.jpg"),
    },
    {
      step: "Step 5",
      title: "Maintence",
      content:
        "Rest assured, we will provide 6 months of maintenance to ensure everything going good",
      image: require("./../../assets/images/borderless/steps/step-5.jpg"),
    },
  ];

  return (
    <section {...props} className={outerClasses}>
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className={splitClasses}>
            {_.chunk(steps, 2).map((v, i) => {
              return (
                <>
                  <div className="split-item">
                    <div
                      className="split-item-content center-content-mobile reveal-from-left"
                      data-reveal-container=".split-item"
                    >
                      <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                        {v[0].step}
                      </div>
                      <h3 className="mt-0 mb-12">{v[0].title}</h3>
                      <p className="m-0">{v[0].content}</p>
                    </div>
                    <div
                      className={classNames(
                        "split-item-image center-content-mobile reveal-from-bottom",
                        imageFill && "split-item-image-fill"
                      )}
                      data-reveal-container=".split-item"
                    >
                      <Image
                        src={v[0].image}
                        alt={v[0].step}
                        width={528}
                        height={396}
                      />
                    </div>
                  </div>
                  {v[1] ? (
                    <div className="split-item">
                      <div
                        className="split-item-content center-content-mobile reveal-from-right"
                        data-reveal-container=".split-item"
                      >
                        <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                          {v[1].step}
                        </div>
                        <h3 className="mt-0 mb-12">{v[1].title}</h3>
                        <p className="m-0">{v[1].content}</p>
                      </div>
                      <div
                        className={classNames(
                          "split-item-image center-content-mobile reveal-from-bottom",
                          imageFill && "split-item-image-fill"
                        )}
                        data-reveal-container=".split-item"
                      >
                        <Image
                          src={v[1].image}
                          alt={v[1].step}
                          width={528}
                          height={396}
                        />
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                </>
              );
            })}
          </div>
        </div>
      </div>
    </section>
  );
};

FeaturesSplit.propTypes = propTypes;
FeaturesSplit.defaultProps = defaultProps;

export default FeaturesSplit;
