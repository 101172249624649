import React from "react";
import classNames from "classnames";
import { SectionTilesProps } from "../../utils/SectionProps";
import SectionHeader from "./partials/SectionHeader";
import Image from "../elements/Image";
import { Carousel } from "react-carousel-minimal";

const propTypes = {
  ...SectionTilesProps.types,
};

const defaultProps = {
  ...SectionTilesProps.defaults,
};
const FeaturesTiles = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {
  const outerClasses = classNames(
    "features-tiles section",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "features-tiles-inner section-inner ",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  const tilesClasses = classNames(
    "tiles-wrap center-content",
    pushLeft && "push-left"
  );

  const sectionHeader = {
    title: "Technologies we use",
    paragraph: "",
  };

  const data = [
    {
      image: require("./../../assets/images/borderless/services/mobile.png"),
      caption: "Mobile Apps",
    },
    {
      image: require("./../../assets/images/borderless/services/website.png"),
      caption: "Responsive website",
    },
    {
      image: require("./../../assets/images/borderless/services/crypto.png"),
      caption: "Blockchain & Crypto",
    },
    {
      image: require("./../../assets/images/borderless/services/cms.png"),
      caption: "Content Management System",
    },
    {
      image: require("./../../assets/images/borderless/services/sms-email.png"),
      caption: "SMS & Email services",
    },
  ];

  const captionStyle = {
    fontSize: "1.3em",
    fontWeight: "bold",
  };
  const ourServicesSectionHeader = {
    title: "Our Services",
    paragraph: "",
  };

  return (
    <>
      <section {...props} className={outerClasses}>
        <div className="container">
          <div className={innerClasses}>
            <SectionHeader
              data={ourServicesSectionHeader}
              className="center-content"
            />
            <div className={tilesClasses}>
              <Carousel
                data={data}
                time={2000}
                width="1200px"
                height="500px"
                captionStyle={captionStyle}
                radius="10px"
                captionPosition="bottom"
                automatic={true}
                slideBackgroundColor="darkgrey"
                slideImageFit="cover"
                style={{
                  textAlign: "center",
                  maxWidth: "1200px",
                  maxHeight: "500px",
                }}
              />
            </div>
          </div>
        </div>
      </section>
      <section {...props} className={outerClasses}>
        <div className="container">
          <div className={innerClasses}>
            <SectionHeader data={sectionHeader} className="center-content" />
            <div className={tilesClasses}>
              <div className="tiles-item reveal-from-bottom">
                <div className="tiles-item-inner">
                  <div className="features-tiles-item-header">
                    <div className="features-tiles-item-image mb-16">
                      <Image
                        src={require("./../../assets/images/borderless/technologies/Flutter.png")}
                        alt="Flutter"
                        width={120}
                        height={120}
                      />
                    </div>
                  </div>
                  {/* <div className="features-tiles-item-content">
               <h4 className="mt-0 mb-8">Flutter</h4>
              <p className="m-0 text-sm">
                 Professional interface, multiplex device capabilities, multi
                 functionalities at economy costs
               </p> 
             </div> */}
                </div>
              </div>

              <div
                className="tiles-item reveal-from-bottom"
                data-reveal-delay="200"
              >
                <div className="tiles-item-inner">
                  <div className="features-tiles-item-header">
                    <div className="features-tiles-item-image mb-16">
                      <Image
                        src={require("./../../assets/images/borderless/technologies/React-Native.png")}
                        alt="React Native"
                        width={120}
                        height={120}
                      />
                    </div>
                  </div>
                  {/* <div className="features-tiles-item-content">
               <h4 className="mt-0 mb-8">Mobile Apps</h4>
               <p className="m-0 text-sm">
                 Specified on mobile devices, create a deep and loyal
                 relationship with clients
               </p>
             </div> */}
                </div>
              </div>

              <div
                className="tiles-item reveal-from-bottom"
                data-reveal-delay="200"
              >
                <div className="tiles-item-inner">
                  <div className="features-tiles-item-header">
                    <div className="features-tiles-item-image mb-16">
                      <Image
                        src={require("./../../assets/images/borderless/technologies/React.png")}
                        alt="React"
                        width={120}
                        height={120}
                      />
                    </div>
                  </div>
                  {/* <div className="features-tiles-item-content">
               <h4 className="mt-0 mb-8">Mobile Apps</h4>
               <p className="m-0 text-sm">
                 Specified on mobile devices, create a deep and loyal
                 relationship with clients
               </p>
             </div> */}
                </div>
              </div>

              <div
                className="tiles-item reveal-from-bottom"
                data-reveal-delay="400"
              >
                <div className="tiles-item-inner">
                  <div className="features-tiles-item-header">
                    <div className="features-tiles-item-image mb-16">
                      <Image
                        src={require("./../../assets/images/borderless/technologies/NEXT.png")}
                        alt="NEXT"
                        width={120}
                        height={120}
                      />
                    </div>
                  </div>
                  {/* <div className="features-tiles-item-content">
               <h4 className="mt-0 mb-8">Crypto</h4>
               <p className="m-0 text-sm">
                 Evidence-driven design, engaging and outstanding experiences
                 towards audience
               </p>
             </div> */}
                </div>
              </div>

              <div className="tiles-item reveal-from-bottom">
                <div className="tiles-item-inner">
                  <div className="features-tiles-item-header">
                    <div className="features-tiles-item-image mb-16">
                      <Image
                        src={require("./../../assets/images/borderless/technologies/Firebase.png")}
                        alt="Firebase"
                        width={120}
                        height={120}
                      />
                    </div>
                  </div>
                  {/* <div className="features-tiles-item-content">
               <h4 className="mt-0 mb-8">CMS</h4>
               <p className="m-0 text-sm">
                 Evidence-driven design, engaging and outstanding experiences
                 towards audience
               </p>
             </div> */}
                </div>
              </div>

              <div
                className="tiles-item reveal-from-bottom"
                data-reveal-delay="200"
              >
                <div className="tiles-item-inner">
                  <div className="features-tiles-item-header">
                    <div className="features-tiles-item-image mb-16">
                      <Image
                        src={require("./../../assets/images/borderless/technologies/MongoDB.png")}
                        alt="MongoDB"
                        width={120}
                        height={120}
                      />
                    </div>
                  </div>
                  {/* <div className="features-tiles-item-content">
               <h4 className="mt-0 mb-8">SMS & Email Service</h4>
               <p className="m-0 text-sm">
                 Neat solutions on online business and digital marketing
                 Customized enterprise system solutions implementation
               </p>
             </div> */}
                </div>
              </div>

              <div
                className="tiles-item reveal-from-bottom"
                data-reveal-delay="400"
              >
                <div className="tiles-item-inner">
                  <div className="features-tiles-item-header">
                    <div className="features-tiles-item-image mb-16">
                      <Image
                        src={require("./../../assets/images/borderless/technologies/node.png")}
                        alt="NodeJS"
                        width={120}
                        height={120}
                      />
                    </div>
                  </div>
                  {/* <div className="features-tiles-item-content">
               <h4 className="mt-0 mb-8">Maintenance</h4>
               <p className="m-0 text-sm">
                 Evidence-driven design, engaging and outstanding experiences
                 towards audience
               </p>
             </div> */}
                </div>
              </div>

              <div
                className="tiles-item reveal-from-bottom"
                data-reveal-delay="400"
              >
                <div className="tiles-item-inner">
                  <div className="features-tiles-item-header">
                    <div className="features-tiles-item-image mb-16">
                      <Image
                        src={require("./../../assets/images/borderless/technologies/fastlane.png")}
                        alt="Fastlane"
                        width={120}
                        height={120}
                      />
                    </div>
                  </div>
                  {/* <div className="features-tiles-item-content">
               <h4 className="mt-0 mb-8">Maintenance</h4>
               <p className="m-0 text-sm">
                 Evidence-driven design, engaging and outstanding experiences
                 towards audience
               </p>
             </div> */}
                </div>
              </div>

              <div
                className="tiles-item reveal-from-bottom"
                data-reveal-delay="400"
              >
                <div className="tiles-item-inner">
                  <div className="features-tiles-item-header">
                    <div className="features-tiles-item-image mb-16">
                      <Image
                        src={require("./../../assets/images/borderless/technologies/PM2.png")}
                        alt="PM2"
                        width={120}
                        height={120}
                      />
                    </div>
                  </div>
                  {/* <div className="features-tiles-item-content">
               <h4 className="mt-0 mb-8">Maintenance</h4>
               <p className="m-0 text-sm">
                 Evidence-driven design, engaging and outstanding experiences
                 towards audience
               </p>
             </div> */}
                </div>
              </div>

              <div
                className="tiles-item reveal-from-bottom"
                data-reveal-delay="400"
              >
                <div className="tiles-item-inner">
                  <div className="features-tiles-item-header">
                    <div className="features-tiles-item-image mb-16">
                      <Image
                        src={require("./../../assets/images/borderless/technologies/aws.png")}
                        alt="AWS"
                        width={120}
                        height={120}
                      />
                    </div>
                  </div>
                  {/* <div className="features-tiles-item-content">
               <h4 className="mt-0 mb-8">Maintenance</h4>
               <p className="m-0 text-sm">
                 Evidence-driven design, engaging and outstanding experiences
                 towards audience
               </p>
             </div> */}
                </div>
              </div>

              <div
                className="tiles-item reveal-from-bottom"
                data-reveal-delay="400"
              >
                <div className="tiles-item-inner">
                  <div className="features-tiles-item-header">
                    <div className="features-tiles-item-image mb-16">
                      <Image
                        src={require("./../../assets/images/borderless/technologies/twilio.png")}
                        alt="twilio"
                        width={120}
                        height={120}
                      />
                    </div>
                  </div>
                  {/* <div className="features-tiles-item-content">
               <h4 className="mt-0 mb-8">Maintenance</h4>
               <p className="m-0 text-sm">
                 Evidence-driven design, engaging and outstanding experiences
                 towards audience
               </p>
             </div> */}
                </div>
              </div>

              <div
                className="tiles-item reveal-from-bottom"
                data-reveal-delay="400"
              >
                <div className="tiles-item-inner">
                  <div className="features-tiles-item-header">
                    <div className="features-tiles-item-image mb-16">
                      <Image
                        src={require("./../../assets/images/borderless/technologies/SQL.png")}
                        alt="SQL"
                        width={120}
                        height={120}
                      />
                    </div>
                  </div>
                  {/* <div className="features-tiles-item-content">
               <h4 className="mt-0 mb-8">Maintenance</h4>
               <p className="m-0 text-sm">
                 Evidence-driven design, engaging and outstanding experiences
                 towards audience
               </p>
             </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

FeaturesTiles.propTypes = propTypes;
FeaturesTiles.defaultProps = defaultProps;

export default FeaturesTiles;
