import React, { useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { SectionProps } from "../../utils/SectionProps";
import Input from "../elements/Input";
import emailjs from "@emailjs/browser";

const propTypes = {
  ...SectionProps.types,
  split: PropTypes.bool,
};

const defaultProps = {
  ...SectionProps.defaults,
  split: false,
};

const Cta = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  split,
  ...props
}) => {
  const outerClasses = classNames(
    "cta section center-content-mobile reveal-from-bottom",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "cta-inner section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider",
    split && "cta-split"
  );

  const [email, setEmail] = useState("");
  const [sent, setSent] = useState(false);

  const sendEmail = async (e) => {
    e.preventDefault();
    await emailjs.send(
      "service_mpv42j6",
      "template_zdiqwae",
      { email, to_name: "Whitman Lui" },
      "UlbapJO606uv4k7Cu"
    );
    setSent(true);
  };

  return (
    <section {...props} className={outerClasses}>
      <div className="container">
        <div className={innerClasses}>
          <div className="cta-slogan">
            <h3 className="m-0">TO KICKSTART YOUR PRODUCT?</h3>
          </div>
          <div className="cta-action" style={{ flex: 1 }}>
            {sent ? (
              <div>
                Thanks for submitting, I will get back to you within 3 days
              </div>
            ) : (
              <form onSubmit={sendEmail}>
                <Input
                  id="newsletter"
                  type="email"
                  label="Subscribe"
                  labelHidden
                  hasIcon="right"
                  placeholder="Your email"
                  onChange={(v) => setEmail(v.target.value)}
                  value={email}
                >
                  <svg
                    width="16"
                    height="12"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9 5H1c-.6 0-1 .4-1 1s.4 1 1 1h8v5l7-6-7-6v5z"
                      fill="#376DF9"
                    />
                  </svg>
                </Input>
              </form>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

Cta.propTypes = propTypes;
Cta.defaultProps = defaultProps;

export default Cta;
